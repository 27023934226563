import React from "react";
import Img from "gatsby-image";

const SingleColumnHero = ({ eyebrow, header, headerSize, image, video }) => {
  return (
    <section
      className={`single-column-hero${
        !!image || !!video ? " with-bg t-white" : ""
      }`}
    >
      {(!!image || !!video) && (
        <>
          <div className="single-column-hero__bg">
            {!!image && (
              <Img
                alt={image.alt_text || ""}
                fluid={image.localFile.childImageSharp.fluid}
              />
            )}
            {!!video && (
              <video id="hero-video" playsInline muted autoPlay loop>
                <source
                  src={video}
                  type="video/mp4"
                  playsInline
                  muted
                  loop
                ></source>
              </video>
            )}
          </div>
          <div className="single-column-hero__overlay"></div>
        </>
      )}
      <div className="single-column-hero__content">
        <div className="single-column-hero__content__wrapper">
          {eyebrow && (
            <div className="single-column-hero__content__eyebrow t-eyebrow l-content--medium">
              {eyebrow}
            </div>
          )}
          {header && (
            <div className="single-column-hero__content__title l-content--medium">
              {headerSize ? (
                <h1 className="single-column-hero__content__title-text t-header--xl">
                  {header}
                </h1>
              ) : (
                <div className="single-column-hero__content__title-text t-header--lg">
                  {header}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SingleColumnHero;
