import React from "react";
import Img from "gatsby-image";

import eyeGif from "../../images/Eye.gif";

const twoColMediaTextSubheader = ({ bgColor, text, mediaStyle = "", media = "", mediaPlacement = "" }) => {

  return (
    <section style={{ backgroundColor: bgColor || null }} className="two-col-media-text l-vspace">
      <div className="two-col-media-text__content l-content l-content--fixed">
        <div className="two-col-media-text__media l-column--left">
          {(() => { 
            if (mediaStyle == "static" && media) { 
              return <Img alt={media.alt_text} className={`two-col-media-text__img two-col-media-text-col__media--${mediaPlacement}`} fluid={media.localFile.childImageSharp.fluid} />; 
            } else if (mediaStyle == "gif") {
              return <img src={eyeGif} alt="An animated blinking eyeball" />
            }
          })()}
        </div>
        <div className="two-col-media-text__text l-column--right">
          <div className="two-col-media-text__copy t-copy" dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </div>
    </section>
  );
};

export default twoColMediaTextSubheader;
