import React, { Fragment } from "react";
import { genCaseStudyUrl } from "../../js/util";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const renderPractice = practice => (
  <Fragment key={practice.title}>
    {practice.title}
    <br />
  </Fragment>
);

const servicesTableRow = ({ client, brandPractices, experiencePractices }) => {
  return (
    <>
      <div className="rtable-cell rtable-cell--left">
        <AniLink
          className={`rtable-cell--link`}
          to={genCaseStudyUrl(client[0].post_title)}
        >
          <div className="rtable-cell--client">{client[0].post_title}</div>
        </AniLink>
      </div>
      <div className="rtable-cell">
        <div className="rtable-cell--mobile-title t-h6">Brand Design</div>
        {brandPractices.map(renderPractice)}
      </div>
      <div className="rtable-cell rtable-cell--foot">
        <div className="rtable-cell--mobile-title t-h6">Experience Design</div>
        {experiencePractices.map(renderPractice)}
      </div>
    </>
  );
};
export default servicesTableRow;
