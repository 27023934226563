import React from "react";

const PlaceholderSvg = props => (
  <svg
    viewBox="0 0 20 20"
    width="20px"
    height="20px"
    preserveAspectRatio="none"
    {...props}
  >
    <defs>
      <linearGradient id="fillGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#845EC2" />
        <stop offset="20%" stopColor="#D65DB1" />
        <stop offset="40%" stopColor="#FF6F91" />
        <stop offset="60%" stopColor="#FF9671" />
        <stop offset="80%" stopColor="#FFC75F" />
        <stop offset="100%" stopColor="#F9F871" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect fill="url(#fillGradient)" width="20" height="20" />
      <path
        d="M.5 19.5l19-19M.5.5l19 19"
        stroke="white"
        strokeLinecap="square"
        strokeWidth="1px"
        strokeOpacity="0.5"
        vectorEffect="non-scaling-stroke"
      />
    </g>
  </svg>
);
export default PlaceholderSvg;
