import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const SingleColumnText = ({ background, text, cta }) => {
  return (
    <section
      className={`single-column-text ${background ? "bg-dark" : "bg-light"}`}
    >
      <div className="single-column-text__wrapper l-content--medium">
        <div
          className="single-column-text__body"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        {!!cta && (
          <AnchorLink
            to={cta.url}
            className="cta cta--secondary--light cta--page-jump"
          >
            {cta.title}
          </AnchorLink>
        )}
      </div>
    </section>
  );
};

export default SingleColumnText;
