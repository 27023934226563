import React from "react";
import Img from "gatsby-image";

const LeftRightImageStack = ({ background, elems }) => {
  return (
    <section
      className={`left-right-image-stack ${
        background ? "bg-dark" : "bg-light"
      }`}
    >
      <div className="left-right-image-stack__wrapper">
        {elems.map((section, i) => (
          <div
            key={i}
            className={`left-right-image-stack__section${
              section.image_left__right ? " image-right" : ""
            }${elems.length > 1 ? " stagger-sections" : ""}${
              !section.text_image_text_block ? " no-text" : ""
            }`}
          >
            <Img
              className="left-right-image-stack__image"
              alt={section.text_image_the_image.alt_text || ""}
              fluid={
                section.text_image_the_image.localFile.childImageSharp.fluid
              }
            />
            <div className="left-right-image-stack__text">
              <div
                dangerouslySetInnerHTML={{
                  __html: section.text_image_text_block
                }}
              />
              {section.text_image_text_block_image && (
                <Img
                  className=""
                  alt={section.text_image_text_block_image.alt_text || ""}
                  fluid={
                    section.text_image_text_block_image.localFile
                      .childImageSharp.fluid
                  }
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LeftRightImageStack;
