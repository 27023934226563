import React from "react";

//import sunjay from '../../images/sunjay.jpg';
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import PlaceholderSvg from "../helpers/placeholder";

const ThoughtPieceCallout = ({ title, excerpt, authors, legacy, image }) => {
  return (
    <section className="thought-piece-callout l-vspace">
      <div className="thought-piece-callout__content">
        <div className="thought-piece-callout__intro l-content l-content--fixed">
          <h3 className="thought-piece-callout__heading t-h6">Tank thought:</h3>
          <AniLink
            swipe
            direction="left"
            top="entry"
            duration={0.4}
            to={convertToSlug(title, legacy)}
            className="thoughts-archive__title t-h3"
          >
            <h4 className="thought-piece-callout__title t-h3">{title}</h4>
          </AniLink>
          <p className="thought-piece-callout__excerpt">{excerpt}</p>
        </div>
        {/* <div className="thought-piece-callout__authors">
          {renderAuthors(authors)}
        </div> */}
        <div className="l-content">
        {!!image ? (
          <AniLink
            swipe
            direction="left"
            top="entry"
            duration={0.4}
            to={convertToSlug(title, legacy)}
            className="thoughts-archive__image"
          >
          <Img
            alt={image.alt_text ? image.alt_text : ''}
            fluid={image.localFile.childImageSharp.fluid}  
          />
          </AniLink>
        ) : (
          <PlaceholderSvg width="100%" height="200px" />
        )}
        </div>
        <div className="l-content l-content--fixed">
          <AniLink
            swipe
            direction="left"
            top="entry"
            duration={0.4}
            to={"/whats-new"}
            className="thought-piece-callout__cta"
          >
            Read more of our thoughts
          </AniLink>
        </div>
      </div>
    </section>
  );
};

export default ThoughtPieceCallout;

// const renderAuthors = (authors) => {
//   var authorsObj = [];

//   for (var [idx, author] of authors.entries()) {
//       authorsObj.push(
//           <div key={'author-' + idx} className="thought-piece-callout__author">
//               {(() => {
//                 if (author.a_headshot.localFile) {
//                   return <Img className="thought-piece-callout__author-img" alt={author.a_headshot.alt_text} fluid={author.a_headshot.localFile.childImageSharp.fluid}></Img>
//                 }
//               })()}
//               <h5 className="thought-piece-callout__author-name">{author.a_name}</h5>
//               <p className="thought-piece-callout__author-title">{author.a_title}</p>
//               <p className="thought-piece-callout__author-company">{author.a_company}</p>
//           </div>
//       )
//   }

//   return authorsObj;
// }

const convertToSlug = (title, legacy) => {
  let slug = title
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");

  if (legacy) {
    return `/legacy/thought/${slug}`;
  } else if (slug == 'too-many-cooks') {
    return `/work/too-many-cooks`
  } else {
    return `/thought/${slug}`;
  }
};
