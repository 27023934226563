import React from 'react';
import InnerHTML from 'dangerously-set-html-content';
const contactForm = `
    <form name="contact" method="post" netlify content-type="application/x-www-form-urlencoded">
      <input type="hidden" name="form-name" value="contact" />
      <span class="first-name">
        <label for="name">First Name</label>
        <input required type="text" name="first_name">
      </span>
      <span class="last-name">
        <label for="name">Last Name</label> 
        <input required type="text" name="last_name">
      </span>
      <label for="company">Your Company</label>
      <input required type="text" name="company">
      <label for="title">Your Position / Title</label>
      <input type="text" name="title">
      <label for="media_box_email">Your Email</label>
      <input required type="email" name="media_box_email">
      <label for="media_box_phone">Your Phone</label>
      <input type="tel" name="media_box_phone">
      <label for="task_box">Any Questions?</label>
      <textarea name="task_box"></textarea>
      <span class="subscribe">
        <input type="checkbox" id="subscribe" name="subscribe">
        <label for="subscribe"></label>
        <span>Subscribe to occasional emails about Tank news and events</span><br/>
      </span>
      <button type="submit" name="submit">Submit</button>
      
    </form>`;

const BusinessContact = ({ id, intro, contact }) => {
    
    return (
      <section
        className="business-contact l-content l-content--fixed l-vspace l-col-container"
        id={id}
      >
        <div className="l-column--left"></div>
        <div className="l-column--right">
          <h4 className="business-contact__intro t-h5">{intro}</h4>

          <div
            className="form"
            dangerouslySetInnerHTML={{ __html: contactForm }}
          ></div>
        </div>
      </section>
    );
};

export default BusinessContact;
