import React from "react";

import StatisticsColumn from './StatisticsColumn';

const statisticsColumns = ({ stats = "", textColor = "", bgColors = "", numColumns = "" }) => {
    const getLayoutClass = (numColumns) => {
        if (numColumns < 3) {
            return 'two-col';
        } else {
            return 'three-col';
        }
    }

    var styleObj = {
        color: textColor
    }

    return (
        <div style={styleObj} className={`statistics-columns statistics-columns--${getLayoutClass(numColumns)}  l-content l-vspace`}>
            {stats.map((stat, index) => <StatisticsColumn key={index} stat={stat} bgColor={bgColors[index].color} />)}
        </div>
    );
};

export default statisticsColumns;

