import React from "react";

const Careers = ({ header, listings }) => {
  return (
    <section id="openings" className="careers">
      <div className="">
        <div className="t-header--md">{header}</div>
        {!!listings ? (
          listings.map((listing, i) => (
            <div className="careers__department" key={i}>
              <div className="t-header--xs">{listing.job_department_header}</div>
              {listing.job_opening.map((opening, j) => (
                <div className="careers__opening" key={j}>
                  <div className="careers__opening__position">
                    <a href={opening.position_cta.url}>
                      {opening.position_title}
                    </a>
                  </div>
                  <a
                    href="{opening.position_cta.url}"
                    className="cta cta--secondary--dark"
                  >
                    {opening.position_cta.title}
                  </a>
                </div>
              ))}
            </div>
          ))
        ) : (
          `<div className="t-header--xs">We've got a full tank right now, but we're always interested in hearing from new people who could be future coworkers. Send us your information, and we'll reach out when we have a good match. </div>`
        )}
        
      </div>
    </section>
  );
};

export default Careers;
