import React from "react";
import Img from "gatsby-image";

const Quote = ({ id, intro, quote, quotee, image }) => {
  return (
    <section
      className={`quote ${
        image ? "with-img" : "l-content l-content--fixed l-vspace"
      }`}
      id={id}
    >
      {image && (
        <div className="quote__image">
          <Img
            alt={image.alt_text}
            fluid={image.localFile.childImageSharp.fluid}
          />
        </div>
      )}
      <div className="quote__body">
        
        {(() => { if (intro) { return <h4 className="quote__intro t-h6" dangerouslySetInnerHTML={{__html: intro}}></h4>; } })()}
        <div className="quote__blockquote">
          {image && <div className="quote__mark">“</div>}
          <div className="quote__content">
            <div className="quote__text t-h3">{quote}</div>
            {(() => { if (quotee) { return <div className="quote__text quote__quotee" dangerouslySetInnerHTML={{__html: `&mdash; ` + quotee}}></div>; } })()}
            
          </div>
          {image && <div className="quote__mark">„</div>}
        </div>
      </div>
    </section>
  );
};

export default Quote;
