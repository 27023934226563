import React from "react";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import TransitionLink from "gatsby-plugin-transition-link";

import { genCaseStudyUrl } from "../../js/util";
import PlaceholderSvg from "../helpers/placeholder";

const relatedCaseStudies = ({ title, studies }) => {
  const studiesList = studies.slice(0, 4);  

  return (
    <section className="related-case-studies l-content">
      <h4 className="related-case-studies__title t-h6 l-content--fixed">{title}</h4>
      <div className="related-case-studies__grid t-white">
        {studiesList.map((study, i) => (
          // <AniLink 
          //   swipe
          //   direction="left"
          //   top="entry"
          //   duration={0.4}
          //   className="related-case-item"
          //   to={genCaseStudyUrl(study.case_study[0].post_title)}
          //   key={`${i}-${study.case_study[0].post_title}`}
          // >
          <TransitionLink
            className="related-case-item"
            to={genCaseStudyUrl(study.case_study[0].post_title)}
            key={`${i}-${study.case_study[0].post_title}`}
          >
                  
                
            <div className="related-case-item__img">
              {!!study.image && !!study.image.localFile ? (
                <Img
                  alt={study.image.alt_text}
                  fluid={study.image.localFile.childImageSharp.fluid}
                />
              ) : (
                <PlaceholderSvg width="100%" height="100%" />
              )}
            </div>
            <div className="related-case-item__content">
              <h5 className="related-case-item__title t-h5">
                {study.case_study[0].post_title}
              </h5>
            </div>
          </TransitionLink>
          // </AniLink>
        ))}
      </div>
      <div className="related-case-studies__btn-row">
        <AniLink
          swipe
          direction="left"
          top="entry"
          duration={0.4}
          to="/work"
          className="t-btn"
        >
          View all
        </AniLink>
      </div>
    </section>
  );
};

export default relatedCaseStudies;
