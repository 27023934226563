import React, { useEffect } from "react";
//import healthcareCapabilities from "file-loader!../../images/healthcare_capabilities.vtt";

const ServiceDetailHero = ({ context = "", video = "", poster }) => {
  useEffect(() => {
    const vid = document.querySelector(".js-service-vid");
    const top = vid.getBoundingClientRect().top;
    const bottom = vid.getBoundingClientRect().bottom;

    const handleServiceVid = _ => {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > top - 600) {
        if (!vid.classList.contains("is-active")) {
          vid.play();
          vid.classList.add("is-active");
          vid.classList.add("full-opacity");
        }
      } else {
        vid.pause();
        vid.classList.remove("is-active");
      }
    };
     //sound toggle
    let playSound = document.querySelector('.service-detail-hero__video--button');
    let videoHero = document.getElementById('SDHeroVideo');

    playSound.addEventListener('click', () => {

        if (playSound.classList.contains('play')) {
            playSound.classList.remove('play');
            document.getElementById('play-mute').innerHTML =("on");
            videoHero.muted = true;
        } else {
            playSound.classList.add('play');
            document.getElementById('play-mute').innerHTML =("off");
            videoHero.muted = false;
        }

    });
    window.addEventListener("scroll", handleServiceVid);
    window.addEventListener("load", handleServiceVid);

    return _ => {
      window.removeEventListener("scroll", handleServiceVid);
      window.addEventListener("load", handleServiceVid);
    };

   
  }, []);
  return (
    <section className="service-detail-hero l-vspace">
      <div className="service-detail-hero__video-container">
        <div className="service-detail-hero__video">
          <video id="SDHeroVideo"
            paused="true"
            playsInline
            muted
            loop
            className="js-service-vid"
            poster={
              poster.localFile ? poster.localFile.childImageSharp.fluid.src : ""
            }
          >
            <source src={video} type="video/mp4"></source>
            {/* <track
              label="English"
              kind="subtitles"
              srclang="en"
              src={healthcareCapabilities}
              default /> */}
          </video>
        </div>
      </div>
      <div className="service-detail-hero__copy">
        <div className="l-content l-content--fixed">
          <div
            className="service-detail-hero__title t-h1"
            dangerouslySetInnerHTML={{ __html: getHeroTitle(context) }}
          ></div>
        </div>
        
      </div>
      <div className="service-detail-hero__video--button-wrapper">
          <div className="l-content l-content--fixed">
            <button className="service-detail-hero__video--button l-content l-content--fixed">Sound <span id="play-mute">on</span></button>  
            
          </div>  
      </div>
    </section>
  );
};

export default ServiceDetailHero;

const getHeroTitle = context => {
  if (context === "brand") {
    return '<span class="t-turquoise">Brand</span>is <br>Experience';
  } else if (context === "experience") {
    return '<span class="t-hot-pink">Experience</span>is <br>Brand';
  } else {
    return "";
  }
};
