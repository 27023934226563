import React, { Fragment } from "react";
import Img from "gatsby-image";
//import AniLink from "gatsby-plugin-transition-link/AniLink";
// import TransitionLink from "gatsby-plugin-transition-link";

const LogoCarousel = ({ logoCarouselCTA, logoCarouselElements }) => {
  const ctaObj = logoCarouselCTA && (
    // <AniLink
    //   swipe
    //   direction="left"
    //   top="entry"
    //   duration={0.4}
    //   to={logoCarouselCTA.url}
    //   className="cta cta--secondary--light cta--arrow-white"
    // >
    //   {logoCarouselCTA.title} 
    // </AniLink>
    // <TransitionLink
    //   to={logoCarouselCTA.url}
    //   className="cta cta--secondary--light cta--arrow-white"
    // >
    //   {logoCarouselCTA.title} 
    // </TransitionLink>
    <a href={logoCarouselCTA.url} className="cta cta--secondary--light cta--arrow-white">{logoCarouselCTA.title} </a>
  );

  const makeLogo = (logoElement, idx) => {
    return (
      <Fragment key={logoElement.logo_carousel_image.alt_text || idx}>
        <div className="logo-carousel__logo" key={idx}>
          {!!logoElement.logo_carousel_image && ( 
            <Img
              alt={logoElement.logo_carousel_image.alt_text || ""}
              fluid={
                logoElement.logo_carousel_image.localFile.childImageSharp.fluid
              }
            />
          )}
        </div>
      </Fragment>
    );
  };
  return (
    <section className="logo-carousel">
      <div className="logo-carousel__content">
        <div className="logo-carousel__content--wrapper">
          <div className="logo-carousel__content--row">
            {logoCarouselElements
              .filter((el, idx) => idx < logoCarouselElements.length / 2)
              .map((logoElement, idx) => {
                return makeLogo(logoElement, idx);
              })}
          </div>
          <div className="logo-carousel__content--row">
            {logoCarouselElements
              .filter((el, idx) => idx >= logoCarouselElements.length / 2)
              .map((logoElement, idx) => {
                return makeLogo(logoElement, idx);
              })}
          </div>
        </div>
        {logoCarouselCTA && (
          <div className="logo-carousel__cta-wrapper">{ctaObj}</div>
        )}
        {/* content-wrapper */}
      </div>
      {/* content */}
    </section>
  );
};

export default LogoCarousel;
