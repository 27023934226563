import React from "react";

import image1 from "../../images/instagramFeed/instagram-image-1.jpg";
import image2 from "../../images/instagramFeed/instagram-image-2.jpg";
import image3 from "../../images/instagramFeed/instagram-image-3.jpg";

const fakeData = [
  {
    imgSrc: image1,
    href: "https://instagram.com/tank_design"
  },
  {
    imgSrc: image2,
    href: "https://instagram.com/tank_design"
  },
  {
    imgSrc: image3,
    href: "https://instagram.com/tank_design"
  }
];

const InstagramFeed = () => {
  return (
    <section className="tank-instagram l-vspace">
      <div className="tank-instagram__title l-content l-content--fixed">
        <h4 className="t-h6">
          Follow{" "}
          <a
            href="https://www.instagram.com/tank_design/"
            target="_blank"
            rel="noopener noreferrer"
          >
            @tank_design
          </a>
        </h4>
      </div>
      <div className="l-content">
        <div id="tankInstafeed" className="row">
          {fakeData.map(({ href, imgSrc }) => {
            return (
              <div key={imgSrc} class="instagram_card col">
                <a href={href} target="_blank" rel="noreferrer">
                  <img class="in" src={imgSrc} alt="Instagram" />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default InstagramFeed;
