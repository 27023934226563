import React from "react";
import Img from "gatsby-image";

const pageContentHeader = ({
  context,
  title,
  smallTitle,
  text,
  heroImage,
  heroImageTitle
}) => {
  var pageClass = smallTitle.replace(" ", "-").replace(" ", "-").replace("'", "").toLowerCase();
  return (
    <section
      className={`page-content-header page-content-header--${context} hero--${pageClass}`}
    >
      <div className="page-content-header__content l-content l-content--fixed">
        <div className="page-content-header-col page-content-header-col--left l-column--left">
          <div className="page-content-header__small-title">
            {renderSmallTitle(context, smallTitle)}
          </div>
        </div>
        <div className="page-content-header-col page-content-header-col--right l-column--right">
          <div
            className="page-content-header__title t-copy"
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <div className="page-content-header__text">
            <p
              className="t-intro"
              dangerouslySetInnerHTML={{ __html: text }}
            ></p>
          </div>
        </div>
      </div>

      {renderServiceHero(context, heroImage, heroImageTitle)}
    </section>
  );
};

export default pageContentHeader;
const renderServiceHero = (context, heroImage, heroImageTitle) => {
  var serviceHeroObj = [];
  if (context === "service_detail") {
    serviceHeroObj.push(
      <div className="page-content-header__img" key={context}>
        {(() => {
          if (heroImage.localFile) {
            return (
              <Img
                alt={heroImage.localFile.alt_text}
                fluid={heroImage.localFile.childImageSharp.fluid}
              />
            );
          }
        })()}
        <div className="page-content-header__img-container">
          <div className="page-content-header__img-content l-content l-content--fixed">
            <span className="page-content-header__img-spacer"></span>
            <p className="page-content-header__img-text t-h6">
              {heroImageTitle}
            </p>
          </div>
        </div>
      </div>
    );
  } // endif
  return serviceHeroObj;
};
const renderSmallTitle = (context, smallTitle) => {
  var smallTitleObj = [];
  smallTitleObj.push(
    <h1 key="small-title" className="t-h6">
      {smallTitle}
    </h1>
  );

  return smallTitleObj;
};
