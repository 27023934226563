import React, { useEffect } from 'react';

const homeHero = ({ title = '', copy = '', video = '', poster = ''}) => {


    useEffect(() => {
        let playSound = document.querySelector('button.home-hero__video--button');
        let videoHero = document.getElementById('homeHeroVideo');

        playSound.addEventListener('click', () => {

            if (playSound.classList.contains('play')) {
                playSound.classList.remove('play');
                document.getElementById('play-mute').innerHTML = ("on");
                videoHero.muted = true;
            } else {
                playSound.classList.add('play');
                document.getElementById('play-mute').innerHTML = ("off");
                videoHero.muted = false;
            }

        });
    }, []) 
    return ( 
        <section className="home-hero">
            <div className="home-hero__content l-content l-content--fixed">
                {(() => { if (title) { return <h1 className="home-hero__title t-h3 t-black" dangerouslySetInnerHTML={{__html: title}}></h1>; } })()}
                <p className="home-hero__copy">{copy}</p>
            </div>

            <div className="home-hero__video l-vspace">
                <video id="homeHeroVideo" playsInline muted autoPlay loop>
                    <source src={video} 
                            type="video/mp4"
                            playsInline
                            muted
                            loop
                            poster={
                            poster.localFile ? poster.localFile.childImageSharp.fluid.src : ""
                            }> 
                            </source>
                </video>
                <div className="home-hero__video--button-wrapper">
                    <div className="l-content l-content--fixed">
                        <button className="home-hero__video--button">Sound <span id="play-mute">on</span></button>  
                    </div>

                </div>
                
            </div>
        </section>
    )
};


export default homeHero;