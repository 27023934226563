import React from "react";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// import sunjay from "../../images/sunjay.jpg";

const thoughtsArchive = ({ posts }) => {
  return (
    <section className="thoughts-archive l-vspace">
      {posts.map((post, index) => (
        <div
          className={`thoughts-archive__post-wrapper thought-post-${index}`}
          key={`thought-post-${index}`}
        >
          <div
            className="thoughts-archive-featured l-content l-content--fixed l-col-container"
            key={post.node.post_title}
          >
            <div className="thoughts-archive__col thoughts-archive__col--left l-column--left">
              <div className="thoughts-archive__cat t-h6">
                {renderThoughtCats(post.node.thought_categories)}
              </div>
              <p className="thoughts-archive__date">
                Posted: {renderPostDate(post.node.post_date)}
              </p>
            </div>
            <div className="thoughts-archive__col thoughts-archive__col--right l-column--right">
              <AniLink
                swipe
                direction="left"
                top="entry"
                duration={0.4}
                to={convertToSlug(post.node.post_title, post.node.acf.legacy)}
                className="thoughts-archive__title t-h3"
              >
                {post.node.post_title}
              </AniLink>
              <p className="thoughts-archive__authors">
                {renderAuthorsName(
                  post.node.acf.thought_piece_blocks[0].block_type[0].authors
                )}
              </p>
              <p className="thoughts-archive__excerpt">
                {post.node.post_excerpt}
              </p>
              <div className="thoughts-archive__headshots">
                {renderAuthorsImg(
                  post.node.acf.thought_piece_blocks[0].block_type[0].authors
                )}
              </div>
            </div>
          </div>
          <div className="divider-line divider-line--half_width l-content l-content--fixed l-col-container">
            <div className="l-column--left"></div>
            <div className="l-column--right">
              <hr></hr>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default thoughtsArchive;

const renderThoughtCats = categories => {
  var thoughtCatsObj = [];
  var i = 0;
  for (var cat of categories) {
    if (cat.name) {
      if (i == 0) {
        thoughtCatsObj.push(
          <span key={`cat-name--${cat.name}`}>{cat.name}</span>
        );
      } else {
        thoughtCatsObj.push(
          <span key={`cat-name--${cat.name}`}>, {cat.name}</span>
        );
      }
    }
    i++;
  }

  return thoughtCatsObj;
};

const convertToSlug = (title, legacy) => {
  let slug = title
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");

  if (legacy) {
    return `/legacy/thought/${slug}`; 
  } else if (slug == 'too-many-cooks') {
    return `/work/too-many-cooks`
  } else {
    return `/thought/${slug}`;
  }
};

const renderPostDate = date => {
  let dateObj = [];

  var dateStr = JSON.parse(JSON.stringify(date));

  var dateFormatObj = new Date(dateStr);
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric"
  };

  dateObj.push(
    <span key={date}>{dateFormatObj.toLocaleDateString("en-US", options)}</span>
  );

  return dateObj;
};

const renderAuthorsName = authors => {
  var authorsNameObj = [];
  var i = 0;
  for (var author of authors) {
    if (author.a_name) {
      if (i === 0) {
        authorsNameObj.push(
          <span key={`author-name--${author.a_name}`}>
            By: {author.a_name}{" "}
          </span>
        );
      } else {
        authorsNameObj.push(
          <span key={`author-name--${author.a_name}`}>and {author.a_name}</span>
        );
      }
    }
    i++;
  }

  return authorsNameObj;
};

const renderAuthorsImg = authors => {
  var authorsImgObj = [];

  for (var author of authors) {
    if (author.a_headshot.localFile) {
      authorsImgObj.push(
        <div
          key={`author--${author.a_name}`}
          className="thoughts-archive-headshot thought-piece-callout__author"
        >
          <Img
            className="thought-piece-callout__author-img thoughts-archive-headshot__img"
            alt={author.a_headshot.alt_text}
            fluid={author.a_headshot.localFile.childImageSharp.fluid}
          ></Img>
        </div>
      );
    }
  }

  return authorsImgObj;
};
