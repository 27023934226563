import React from 'react'

const statisticsCallout = ({ intro = '', text = '', color = '', bgColor = '' }) => {
    var blockStyle = {
        backgroundColor : bgColor, 
        color : color
    }
    return (
    <section 
        className="statistics-callout l-vspace"
        data-sal="fade"
        data-sal-duration="300"
        data-sal-easing="ease"
    >
        <div style={blockStyle} className={`statistics-callout__content l-content`}>
            <span className="statistics-callout__intro">{intro}</span>
            <div className="statistics-callout__text" dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
    </section>
    )
};

export default statisticsCallout;