import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

import { genCaseStudyUrl } from "../../js/util";
import { searchFieldIndex } from "../../js/util";
import { renderRelationshipCaseStudyPractices } from "../../js/util";

const renderCaseStudyImg = (study = "") => {
  if (study.image && study.image.localFile) {
    return (
      <div className="case-study-item__img">
        <Img
          alt={study.image.alt_text}
          fluid={study.image.localFile.childImageSharp.fluid}
        />
      </div>
    );
  }
};

const caseStudyItem = ({ context = "", study = "" }) => {
  let ha = "center";
  let va = "center";
  if (study.size === "lg") {
    ha = study.content_ha;
    va = study.content_va;
  }

  let itemPractices;
  if (context === "work") {
    itemPractices = renderRelationshipCaseStudyPractices(
      study.case_study.categories
    );
  }

  
  let overlayTitle = (study.case_study.acf.case_study_blocks) ?
    study.case_study.acf.case_study_blocks[
      searchFieldIndex(study.case_study.acf.case_study_blocks, "csh_title")
    ].block_type[0].csh_title : study.case_study.post_content;

  const overlay = (
    <div
      className={`case-study-item__overlay bg-${study.overlay_bg_color} t-white`}
    >
      <div className="case-study-item__overlay-content">
        <span
          className={`${
            study.size === "lg" ? "t-h2" : "t-h5 t-white"
          } overlay-title`}
        >
          {overlayTitle}
        </span>
        {(() => {
          if (context === "work") {
            return (
              <div
                className={`case-study-item__practices case-study-item__practices--body t-white`}
              >
                <p>{study.case_study.acf.case_study_key_properties}</p>
              </div>
            );
          }
        })()}
      </div>
    </div>
  ); 

  const mobileOverlay = (
    <div
      className={`case-study-item__inline bg-${study.overlay_bg_color} t-black`}
    >
      <div className="case-study-item__overlay-content">
        <span
          className={`${
            study.size === "lg" ? "t-h2" : "t-h5 t-white"
          } overlay-title`}
        >
          {overlayTitle}
          
        </span>
        <div
          className={`case-study-item__practices case-study-item__practices--body t-black`}
        >
          <p>{study.case_study.acf.case_study_key_properties}</p>
        </div>
        
      </div>
    </div>
  ); 

  return (
    <div 
      className={`case-study-item case-study-item--${study.size}`}
      data-sal="slide-up"
      data-sal-duration="100"
      data-sal-easing="ease"
    >
      {/* <AniLink swipe direction="left" top="entry" duration={0.4} className={`case-study-item__content`} to={genCaseStudyUrl(study.case_study.post_title)}>
        {renderCaseStudyImg(study)}
        <div className={`case-study-item__info t-${study.title_color} ha-${ha} va-${va}`}>
          <h5 className={`case-study-item__title ${study.size == 'lg' ? 't-h2' : 't-h4'}`}>{study.case_study.post_title}</h5>
        </div>
        {overlay}
      </AniLink> */}
      <Link
        className={`case-study-item__content`}
        to={genCaseStudyUrl(study.case_study.post_title, study.case_study.post_type, study.case_study.post_excerpt)}
        exit={{ length: 0.5 }}
        entry={{ delay: 0.5 }}
      >
        {renderCaseStudyImg(study)}
        <div
          className={`case-study-item__info t-${study.title_color} ha-${ha} va-${va}`}
        >
          <h5
            className={`case-study-item__title ${
              study.size === "lg" ? "t-h2" : "t-h4"
            }`}
          >
            {study.case_study.post_title}
          </h5>
        </div>
        {overlay}
      </Link>
      {mobileOverlay}
    </div>
  );
};
export default caseStudyItem;
