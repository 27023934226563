import React from "react";

const twoColText = ({ smallTitle, text, hasCircle, circleColor }) => {
  return (
    <section className={ `two-col-text ${hasCircle ? "two-col-text--has-circle " : ""} l-content l-content--fixed l-vspace` }>
      <div className="two-col-text__col l-column--left">
        <div style={{ borderColor: hasCircle ? circleColor : null }} className="two-col-text__subtitle-wrapper">
          <div className="two-col-text__subtitle t-h6">
            {smallTitle}
          </div>
        </div>
      </div>
      <div className="two-col-text__col l-column--right">
        <div className="two-col-text__text t-copy" dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
    </section>
  );
};

export default twoColText;
