import React, { useState } from "react";

const Accordion = ({ items, cta }) => {
  var openCloseState = items.map((item, i) => {
    return i === 0;
  });

  const [isOpen, setIsOpen] = useState(openCloseState);

  const openClose = index => {
    let state = [...isOpen];
    state[index] = !state[index];
    setIsOpen(state);
  };

  return (
    <section className="accordion">
      <div className="accordion__wrapper">
        {items.map((item, i) => (
          <div className={`accordion__item${isOpen[i] ? " open" : ""}`} key={i}>
            <h2 onClick={() => openClose(i)} className="t-header--md">
              {item.accordion_header}
            </h2>
            <div
              className="accordion__item__body"
              dangerouslySetInnerHTML={{ __html: item.accordion_body_copy }}
            />
          </div>
        ))}
        {cta && (
          <a className="cta cta--secondary--dark" href={cta.url}>
            {cta.title}
          </a>
        )}
      </div>
    </section>
  );
};

export default Accordion;
