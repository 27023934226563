import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const JobListings = props => {
  const { allLever } = useStaticQuery(graphql`
    query {
      allLever {
        group(field: categories___location) {
          fieldValue
          nodes {
            text
            applyUrl
            hostedUrl
          }
        }
      }
    }
  `);

  return (
    <section className="job-listings l-vspace">
      <div className="job-listings__heading l-content l-content--fixed l-content--flex-end">
        <div className="l-column--right">
          <h3 className="t-h6">How to Apply: </h3>
          <p>
          If you're interested in one of our current openings, you can apply via LinkedIn. You'll want to have a copy of your resume (DOC, DOCX, or PDF) ready to upload.
          </p>
          <p>
          Openings available to candidates who are eligible to work in the United States. And, although we can't promise to respond to each and every application, an actual human will read your stuff. 
          </p>
          <p>
          Not seeing the perfect match? Sometimes, we find someone so great that we'll hire them even when we aren't looking, because we know the best talent makes us all better. If that's you, send your resume, portfolio or LinkedIn URL and cover letter to <a href="mailto:work@tankdesign.com">work@tankdesign.com</a>.
          </p>
        </div>
      </div>
      <div className="job-listings__heading l-content l-content--fixed l-content--flex-end">
        <div className="l-column--right">
          <h3 className="t-h6">Open Positions: </h3>
        </div>
      </div>
      <div className="l-content l-content--fixed">
            <div className="l-column--left">
              
            </div>
            <div className="l-column--right">
              
                  <a
                    href="https://www.linkedin.com/jobs/view/3399261296"
                    rel="noopener noreferrer"
                    target="_blank"
                    
                    className="job-listings__job-link"
                  >
                    Senior content strategist
                  </a>
               
            </div>
      </div>
      <div className="l-content l-content--fixed">
            <div className="l-column--left">
             
            </div>
            <div className="l-column--right">
              
                  <a
                    href="https://www.linkedin.com/jobs/view/3399258821"
                    rel="noopener noreferrer"
                    target="_blank"
                    
                    className="job-listings__job-link"
                  >
                    Senior user interface designer
                  </a>
               
            </div>
          </div>
      {/* {!!allLever.group &&
        allLever.group.map((locationGroup, index) => (
          <div className="l-content l-content--fixed" key={index}>
            <div className="l-column--left">
              <h3 className="t-h6">{locationGroup.fieldValue}:</h3>
            </div>
            <div className="l-column--right">
              {!!locationGroup.nodes &&
                locationGroup.nodes.map((job, i) => (
                  <a
                    href={job.hostedUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    key={i}
                    className="job-listings__job-link"
                  >
                    {job.text}
                  </a>
                ))}
            </div>
          </div>
        ))} */}
    </section>
  );
};

export default JobListings;
