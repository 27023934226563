import React from "react";

const statisticsColumn = ({ stat = 0, bgColor = "" }) => {
  return (
    <section
      style={{
        backgroundColor: bgColor
      }}
      className="statistics-column"
    >
      <div className="statistics-column__number t-h6">
        {stat.number}
        <span>%</span>
      </div>
      <div className="statistics-column__text t-h6">{stat.text}</div>
    </section>
  );
};

export default statisticsColumn;
