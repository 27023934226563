import React, { useEffect } from 'react';
import Img from "gatsby-image";


const Media = ({ mediaItems='' }) => {
    
    useEffect(() => {
        let vids = document.querySelectorAll(".js-service-vid");
        

        vids.forEach(function(vid) {
            let handleServiceVid = _ => {

                const top = vid.getBoundingClientRect().top;
                const bottom = vid.getBoundingClientRect().bottom;

                var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                // console.log('scrolltop =' + scrollTop + ' top =' + top + ' bottom =' + bottom);
                // if (scrollTop > top - 400 && scrollTop < bottom + 800) {
                if (scrollTop > top - 400) {
                    vid.play();
                    vid.classList.add("is-active");
                    vid.classList.add('full-opacity');
                } else {
                    vid.pause();
                    vid.classList.remove("is-active");
                }
            }

            window.addEventListener('scroll', handleServiceVid);

            return _ => {
                window.removeEventListener('scroll', handleServiceVid)
            }
        })
    }, [])
    
    return (
        <section className={"media-grid l-content l-vspace"}>
            
            {mediaItems.map((mediaItem, idx) => 
                <div 
                    key={idx} 
                    className={"media-item media-item-" + mediaItem.media_item_size}
                    data-sal="fade"
                    data-sal-duration="300"
                    data-sal-easing="ease"
                >
                
                    {mediaItem.media_item_image_or_video ?
                        <div className={"media-item__video"}>
                            <video
                                paused="true"
                                playsInline
                                muted
                                loop
                                className="js-service-vid"
                                poster={mediaItem.media_image.localFile.childImageSharp.fluid}>
                                <source src={mediaItem.media_video_url} type="video/mp4"></source>
                            </video>
                            
                        </div>
                        :
                        <Img 
                            alt={mediaItem.media_image.alt_text ? mediaItem.media_image.alt_text : ''} 
                            fluid={mediaItem.media_image.localFile.childImageSharp.fluid} 
                            
                        />
                    }
                    

            </div>)
            }
        </section>
    )
    
};

export default Media;
