import React from "react";

const fullWidthWys = ({ content = "" }) => {
  return (
    <section className="full-width-wys">
      <div className="full-width-wys__container l-content l-content--fixed">
        <div
          className="full-width-wys__content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </section>
  );
};

export default fullWidthWys;
