import React, { useEffect, Fragment } from "react";
import Img from "gatsby-image";

const tabClickTextImage = ({ title, bodyCopy, tabElements }) => {
  useEffect(() => {
    let tabHeaders = document.querySelectorAll(".tab-text-button");

    tabHeaders.forEach(function(tabHeader) {
      tabHeader.addEventListener("click", () => {
        const tabNumber = tabHeader.dataset.forTab;
        if (tabHeader.classList.contains("button-active")) {
          return;
        } else {
          document
            .querySelector(".tab-text-button.button-active")
            .classList.remove("button-active");
          document
            .querySelector(
              ".tab-click-text-image__tab-image--the-image.image-active"
            )
            .classList.remove("image-active");
          setTimeout(() => {
            tabHeader.classList.add("button-active");
            document
              .querySelector(
                `.tab-click-text-image__tab-image--the-image[data-tab="${tabNumber}"`
              )
              .classList.add("image-active");
          }, 100);
        }
      });
    });
  }, []);
  return (
    <section className="tab-click-text-image">
      <div className="tab-click-text-image__content">
        <div className="tab-click-text-image__5050 tab-text">
          <div className="tab-text--wrapper">
            {title && (
              <div className="tab-click-text-image__title">
                <h2 className="t-header--md">{title}</h2>
              </div>
            )}
            {bodyCopy && (
              <div className="tab-click-text-image__copy">
                <p>{bodyCopy}</p>
              </div>
            )}
            <div className="tab-click-text-image__tab-image--tabs">
              {tabElements.map((tabElement, idx) => (
                <Fragment key={tabElement.tab_text_title}>
                  <button
                    className={`tab-click-text-image__tab-image--the-text tab-text-button${
                      idx === 0 ? ` button-active` : ``
                    }`}
                    data-for-tab={`${idx}`}
                  >
                    {tabElement.tab_text_title && (
                      <h3>{tabElement.tab_text_title}</h3>
                    )}
                  </button>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        {/* tab text */}
        <div className="tab-click-text-image__5050 tab-image">
          {tabElements.map((tabElement, idx) => (
            <Fragment key={tabElement.tab_text_title}>
              <div
                className={`tab-click-text-image__tab-image--the-image${
                  idx == "0" ? ` image-active` : ``
                }`}
                data-tab={`${idx}`}
              >
                {!!tabElement.tab_text_image && (
                  <Img
                    alt={tabElement.tab_text_image.alt_text || ""}
                    fluid={
                      tabElement.tab_text_image.localFile.childImageSharp.fluid
                    }
                  />
                )}
              </div>
            </Fragment>
          ))}
        </div>
        {/* tab-image */}
      </div>
      {/* content */}
    </section>
  );
};

export default tabClickTextImage;
