import React from "react";

import ServicesTableRow from "./ServicesTableRow";

const ServicesTable = ({ rows }) => {
  return (
    <section className="services-table l-content l-content--fixed l-vspace">
      <div className="services-table--table rtable rtable--3cols rtable--collapse">
        <div className="rtable-cell rtable-cell--title rtable-cell--left">
          <div className="t-h6">Client</div>
        </div>
        <div className="rtable-cell rtable-cell--title ">
          <div className="t-h6">Brand Design</div>
        </div>
        <div className="rtable-cell rtable-cell--title ">
          <div className="t-h6">Experience Design</div>
        </div>

        {rows.map(row => (
          <ServicesTableRow
            key={row.client[0].post_title}
            client={row.client}
            brandPractices={row.brand_practices}
            experiencePractices={row.experience_practices}
          />
        ))}
      </div>
    </section>
  );
};

export default ServicesTable;
