import React from 'react'

const fullWidthTitleText = ({ eyebrow = '', alignment = 'left', title = '', text = '', bgColor = '' }) => {
    return (
        <section 
            className={`full-width-title-text full-width-title-text--${alignment} bg-color--${bgColor} l-content l-content--fixed l-vspace`}
            // data-sal="fade"
            // data-sal-duration="300"
            // data-sal-easing="ease"
        >
            <div className="text-background">
            {(() => { if (eyebrow) { return <p className="full-width-title-text__eyebrow t-h6" dangerouslySetInnerHTML={{__html: eyebrow}}></p>; } })()}
            {(() => { if (title) { return <h2 className="full-width-title-text__title t-h3" dangerouslySetInnerHTML={{__html: title}}></h2>; } })()}
            {(() => { if (text) { return <div className="full-width-title-text__text t-copy" dangerouslySetInnerHTML={{__html: text}}></div>; } })()}
            </div>
        </section>
    )
};

export default fullWidthTitleText;