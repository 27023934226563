import React from "react";
import Img from "gatsby-image";

const SingleColumnImage = ({ image }) => {
  return (
    <section className="single-column-image">
      {!!image && (
        <div className="single-column-image__content">
          <Img
            alt={image.alt_text || ""}
            fluid={image.localFile.childImageSharp.fluid}
          />
        </div>
      )}
    </section>
  );
};

export default SingleColumnImage;
