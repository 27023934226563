import React from "react";
//import AniLink from "gatsby-plugin-transition-link/AniLink";
// import TransitionLink from "gatsby-plugin-transition-link";

const EnumeratedTextBlock = ({
  header,
  body,
  cta,
  enumerated,
  blocks,
  background
}) => {
  const bgStyle = {
    backgroundImage: !!background
      ? `url(${background.localFile.publicURL})`
      : "none"
  };

  const ctaObj = cta && (
    // <AniLink
    //   swipe
    //   direction="left"
    //   top="entry"
    //   duration={0.4}
    //   to={cta.url}
    //   className="cta cta--secondary--light"
    // >
    //   {cta.title}
    // </AniLink>
    
    <a href={cta.url} className="cta cta--secondary--light">{cta.title} </a>
  );

  return (
    <section
      className={`enumerated-text-block${!!background ? " with-bg" : ""}`}
      style={bgStyle}
    >
      <div className="enumerated-text-block__content">
        <div className="enumerated-text-block__content__wrapper">
          <div className="enumerated-text-block__content__row">
            {header && (
              <div className="enumerated-text-block__content__col enumerated-text-block__content__title">
                <h2 className="enumerated-text-block__content__title-text t-header--lg t-white">
                  {header}
                </h2>
              </div>
            )}
            {body && (
              <div className="enumerated-text-block__content__col enumerated-text-block__content__body">
                {body}
              </div>
            )}
            {cta && (
              <div className="enumerated-text-block__content__col mobile-hide">
                {ctaObj}
              </div>
            )}
          </div>
          <div className="enumerated-text-block__content__row">
            <ul className="enumerated-text-block__content__list">
              {blocks.map((block, idx) => (
                <li key={idx}>
                  {enumerated && (
                    <div className="t-header--sm enumerated-text-block__content__list__enum">
                      {idx < 10 && "0" + (idx + 1)}
                    </div>
                  )}
                  <h3
                    className={`${
                      enumerated ? "t-header--sm" : "t-header--lxl"
                    } t-white`}
                    dangerouslySetInnerHTML={{
                      __html: block.text_block_header
                    }}
                  ></h3>
                  <div>{block.text_block_body_copy}</div>
                </li>
              ))}
            </ul>
            {cta && <div className="mobile-show">
                      {ctaObj}
                    </div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EnumeratedTextBlock;
