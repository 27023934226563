import React from "react";

/* Import your page blocks here  */

import PageContentHeader from "../components/blocks/PageContentHeader";
import TwoColMediaTextSubheader from "../components/blocks/TwoColMediaTextSubheader";
import DividerLine from "../components/blocks/DividerLine";
import HomeHero from "../components/blocks/HomeHero";
import ServiceDetailHero from "../components/blocks/ServiceDetailHero";
import ThoughtPieceCallout from "../components/blocks/ThoughtPieceCallout";
import CaseStudyGrid from "../components/blocks/CaseStudyGrid";
import Quote from "../components/blocks/Quote";
import Instagram from "../components/blocks/InstagramFeed";
import ThoughtsArchive from "../components/blocks/ThoughtsArchive";
import TwoColText from "../components/blocks/TwoColText";
import RelatedCaseStudies from "../components/blocks/RelatedCaseStudies";
import Media from "../components/blocks/Media";
import SingleColumnHero from "../components/blocks/SingleColumnHero";
import LeftRightImageStack from "../components/blocks/LeftRightImageStack";
import FullWidthTitleText from "../components/blocks/FullWidthTitleText";
import FullWidthWys from "../components/blocks/FullWidthWys";
import SingleColumnText from "../components/blocks/SingleColumnText";
import StatisticsCallout from "../components/blocks/StatisticsCallout";
import StatisticsColumns from "../components/blocks/StatisticsColumns";
import JobListings from "../components/blocks/JobListings";
import ServicesTable from "../components/blocks/ServicesTable";
import BusinessContact from "../components/blocks/BusinessContact";
import TabClickTextImage from "../components/blocks/TabClickTextImage";
import EnumeratedTextBlock from "../components/blocks/EnumeratedTextBlock";
import Careers from "../components/blocks/Careers";
import Accordion from "../components/blocks/Accordion";
import LogoCarousel from "../components/blocks/LogoCarousel";
import SingleColumnImage from "../components/blocks/SingleColumnImage";

import { genBlockKey } from "../js/util";
import Layout from "../components/global/Layout";

export const renderBlocks = blocksArr => {
  var pageBlocks = [];

  for (var [idx, block] of blocksArr.entries()) {
    pageBlocks.push(renderBlock(block, idx));
  }

  return pageBlocks;
};

export const renderArchiveBlocks = (blocksArr, posts) => {
  var pageBlocks = [];

  for (var [idx, block] of blocksArr.entries()) {
    if (block.block_type[0].acf_fc_layout === "thoughts_archive") {
      pageBlocks.push(
        <ThoughtsArchive
          key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
          posts={posts}
        ></ThoughtsArchive>
      );
    } else {
      pageBlocks.push(renderBlock(block, idx));
    }
  }

  return pageBlocks;
};

const renderBlock = (block, idx) => {
  if (block.block_type[0].acf_fc_layout === "full_width_title_and_text") {
    return (
      <FullWidthTitleText
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        eyebrow={block.block_type[0].fwtt_eyebrow}
        alignment={block.block_type[0].fwtt_alignment}
        title={block.block_type[0].fwtt_title}
        text={block.block_type[0].fwtt_text}
        bgColor={block.block_type[0].fwtt_bg_color}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "full_width_wys") {
    return (
      <FullWidthWys
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        content={block.block_type[0].fww_content}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "thought_piece_callout") {
    return (
      <ThoughtPieceCallout
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        title={block.block_type[0].tpc_thought_piece[0].post_title}
        excerpt={block.block_type[0].tpc_thought_piece[0].post_excerpt}
        legacy={block.block_type[0].tpc_thought_piece[0].acf.legacy}
        image={block.block_type[0].tpc_thought_piece[0].image}
        authors={
          block.block_type[0].tpc_thought_piece[0].acf.thought_piece_blocks[0]
            .block_type[0].authors
        }
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "case_study_grid") {
    return (
      <CaseStudyGrid
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        toggle={block.block_type[0].csg_hide_toggle}
        context={block.block_type[0].csg_context}
        studies={block.block_type[0].csg_case_studies}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "home_hero") {
    return (
      <HomeHero
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        title={block.block_type[0].hh_title}
        copy={block.block_type[0].hh_copy}
        video={block.block_type[0].hh_background_video}
        poster={block.block_type[0].hh_poster}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "job_listings") {
    return (
      <JobListings key={genBlockKey(block.block_type[0].acf_fc_layout, idx)} />
    );
  }

  if (block.block_type[0].acf_fc_layout === "service_detail_hero") {
    return (
      <ServiceDetailHero
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        context={block.block_type[0].sdh_context}
        video={block.block_type[0].sdh_video}
        poster={block.block_type[0].sdh_poster}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "divider_line") {
    return (
      <DividerLine
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        style={block.block_type[0].dl_style}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "page_content_header") {
    return (
      <PageContentHeader
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        context={block.block_type[0].pch_context}
        title={block.block_type[0].pch_title}
        smallTitle={block.block_type[0].pch_small_title}
        text={block.block_type[0].pch_text}
        heroImage={block.block_type[0].pch_hero_image}
        heroImageTitle={block.block_type[0].pch_hero_image_title}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "quote") {
    return (
      <Quote
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        intro={block.block_type[0].q_intro}
        quote={block.block_type[0].q_quote}
        quotee={block.block_type[0].q_quotee}
        image={block.block_type[0].q_quote_image}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "two_col_media_text_subheader") {
    return (
      <TwoColMediaTextSubheader
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        bgColor={block.block_type[0].tcmts_bg_color}
        text={block.block_type[0].tcmts_text}
        mediaStyle={block.block_type[0].tcmts_media_style}
        media={block.block_type[0].tcmts_media}
        mediaPlacement={block.block_type[0].tcmts_media_placement}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "instagram") {
    return (
      <Instagram key={genBlockKey(block.block_type[0].acf_fc_layout, idx)} />
    );
  }

  if (block.block_type[0].acf_fc_layout === "two_col_text") {
    return (
      <TwoColText
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        smallTitle={block.block_type[0].tct_small_title}
        text={block.block_type[0].tct_text}
        hasCircle={block.block_type[0].tct_has_circle}
        circleColor={block.block_type[0].tct_circle_color}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "related_case_studies") {
    return (
      <RelatedCaseStudies
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        title={block.block_type[0].rcs_intro_title}
        studies={block.block_type[0].rcs_related_cases}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "media") {
    return (
      <Media
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        mediaItems={block.block_type[0].media_item}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "new_hero") {
    return (
      <SingleColumnHero
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        eyebrow={block.block_type[0].hero_eyebrow}
        header={block.block_type[0].hero_header}
        headerSize={block.block_type[0].hero_header_size}
        image={block.block_type[0].hero_image}
        video={block.block_type[0].hero_video_link}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "single_column_text") {
    return (
      <SingleColumnText
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        background={block.block_type[0].single_column_light__dark_background}
        text={block.block_type[0].single_column_text_block}
        cta={block.block_type[0].single_column_cta}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "enumerated_text_block") {
    return (
      <EnumeratedTextBlock
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        header={block.block_type[0].enumerated_steps_header}
        body={block.block_type[0].enumerated_steps_body_text}
        cta={block.block_type[0].enumerated_steps_cta}
        enumerated={block.block_type[0].enumerated_steps}
        blocks={block.block_type[0].enumerated_steps_the_text_blocks}
        background={block.block_type[0].enumerated_steps_background_image}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "left__right_image_stack") {
    return (
      <LeftRightImageStack
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        background={block.block_type[0].text_image_light__dark_background}
        elems={block.block_type[0].text_image_elements}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "careers_block") {
    return (
      <Careers
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        header={block.block_type[0].careers_header}
        listings={block.block_type[0].careers_department}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "accordion_block") {
    return (
      <Accordion
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        items={block.block_type[0].accordion_items}
        cta={block.block_type[0].accordion_cta}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "single_column_image") {
    return (
      <SingleColumnImage
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        image={block.block_type[0].single_column_image}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "statistics_callout") {
    return (
      <StatisticsCallout
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        intro={block.block_type[0].sc_intro}
        text={block.block_type[0].sc_text}
        color={block.block_type[0].sc_text_color}
        bgColor={block.block_type[0].sc_background_color}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "statistics_columns") {
    return (
      <StatisticsColumns
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        stats={block.block_type[0].statc_stats}
        textColor={block.block_type[0].statc_text_color}
        bgColors={block.block_type[0].statc_background_colors}
        numColumns={block.block_type[0].statc_num_columns}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "business_contact") {
    return (
      <BusinessContact
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        intro={block.block_type[0].bi_business_intro}
        contact={block.block_type[0].bi_business_contact}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "services_table") {
    return (
      <ServicesTable
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        rows={block.block_type[0].st_rows}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "tab_click_text_image") {
    return (
      <TabClickTextImage
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        title={block.block_type[0].tab_text_image_header}
        bodyCopy={block.block_type[0].tab_text_body_copy}
        tabElements={block.block_type[0].tab_text_elements}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "logo_carousel") {
    return (
      <LogoCarousel
        key={genBlockKey(block.block_type[0].acf_fc_layout, idx)}
        logoCarouselCTA={block.block_type[0].logo_carousel_cta}
        logoCarouselElements={block.block_type[0].logo_carousel_items}
      />
    );
  }

  if (block.block_type[0].acf_fc_layout === "campaign") {
    return (
      <Layout>
        <div style={{ margin: '120px auto', width: '100%' }}>
          <div className="" style={{
            margin: '0 auto',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',

          }}>
            <iframe src="https://player.vimeo.com/video/990717774?h=aacc61f023&color=4D148c&title=0&byline=0&portrait=0" width="1280" height="720" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </Layout>
    )
  }
};
