import React, { useEffect, Fragment } from "react";

import { genCaseStudyUrl } from "../../js/util";
//import AniLink from "gatsby-plugin-transition-link/AniLink";
import TransitionLink from "gatsby-plugin-transition-link";
import CaseStudyItem from "./CaseStudyItem";

import gridView from "../../images/grid-view.svg";
import listView from "../../images/list-view.svg";

import gridViewInactive from "../../images/grid-view-inactive.svg";
import listViewInactive from "../../images/list-view-inactive.svg";

// import { renderCaseStudyPracticesTwo } from "../../js/util";

const CaseStudyGrid = ({ context = "", studies = "", toggle = "" }) => {
  useEffect(() => {
    let caseStudyItems = document.querySelectorAll(".case-study-item__overlay");
    caseStudyItems.forEach(function(caseStudyItem, idx) {
      
      let handleCaseStudyScroll = _ => {
        const scrollBounds = caseStudyItem.getBoundingClientRect();
        if(scrollBounds.top < window.innerHeight - 50) {
          caseStudyItem.classList.add("is-scrolled");
        }
        else {
          caseStudyItem.classList.remove("is-scrolled");
        }
    
      }

      let colorBlockFade = _ => {
        if(caseStudyItem.classList.contains('is-scrolled') && !caseStudyItem.classList.contains('fade-out')) {
          // setTimeout(function() {
          //   setInterval(function () {
            
            const scrollBounds = caseStudyItem.getBoundingClientRect();
            if(scrollBounds.top < window.innerHeight - 50) {
              if (!caseStudyItem.style.opacity) {
                  caseStudyItem.style.opacity = 1;
              }
              // if (caseStudyItem.style.opacity > 0) {
              //     caseStudyItem.style.opacity -= 0.2;
              // } else {
              //     caseStudyItem.style.opacity = 0;
              // }
              setTimeout(() => {
                caseStudyItem.classList.add('fade-out');
              }, 100);
            }
          //   }, 100)}, 
          // idx*20);
          //caseStudyItem.classList.add('fade-in');
        }
      }

      window.addEventListener('scroll', handleCaseStudyScroll);
      window.addEventListener('scroll', colorBlockFade);

      return _ => {
        window.removeEventListener('scroll', handleCaseStudyScroll);
        window.removeEventListener('scroll', colorBlockFade);
      }

    });
    
    let toggles = document.querySelectorAll(".viewToggle");
    let gridView = document.querySelector(".case-study-grid:not(.toggle-off--true)");
    let listView = document.querySelector(".services-table:not(.toggle-off--true)");
    
    toggles.forEach(function(toggle) {
      toggle.addEventListener("click", () => {
        // const toggleID = toggle.getAttribute("id");

        if (toggle.classList.contains('listView')) {
          if (toggle.classList.contains("active")) {
            return;
          } else {
            let gridViewToggles = document.querySelectorAll(".gridView");
            gridViewToggles.forEach(function(gvt) {
              gvt.classList.remove("active");
            })
            toggle.classList.add("active");
            gridView.style.display = "none";
            listView.style.display = "block";
          }
        }

        if (toggle.classList.contains('gridView')) {
          if (toggle.classList.contains("active")) {
            return;
          } else {
            let listViewToggles = document.querySelectorAll(".listView");
            listViewToggles.forEach(function(lvt) {
              lvt.classList.remove("active");
            })
            toggle.classList.add("active");
            listView.style.display = "none";
            gridView.style.display = "block";
          }
        }
      });
    });
  }, []);

  return (
    <div className="case-study-grid-list">
      <div className={`case-study-grid__toggle l-content toggle-off--${toggle}`}>
        <span className="gridView viewToggle active">
          <img className="active-icon" src={gridView} alt="An icon representing case study view" />
          <img className="inactive-icon" src={gridViewInactive} alt="An icon representing case study view" />
        </span>
        <span className="viewToggle--divider"> | </span>
        <span className="listView viewToggle">
          <img className="active-icon" src={listView} alt="An icon representing list study view" />
          <img className="inactive-icon" src={listViewInactive} alt="An icon representing list study view" />
        </span>
      </div> 
      <section
        className={`case-study-grid case-study-grid--${context} l-content l-vspace toggle-off--${toggle}`}
      >
        <div className="case-study-grid__items">
          {studies.map((study,index) => (
            <CaseStudyItem
              key={'case study' + index}
              context={context}
              study={study}
            />
          ))}
        </div>
      </section>
      <section className={`services-table l-content l-content--fixed l-vspace toggle-off--${toggle}`}>
        <div className="services-table--table rtable rtable--3cols rtable--collapse">
          <div className="rtable-cell rtable-cell--title rtable-cell--left">
            <div className="t-h6">Client</div>
          </div>
          <div className="rtable-cell rtable-cell--title ">
            <div className="t-h6">Featured Services</div>
          </div>
          <div className="rtable-cell rtable-cell--title ">
            <div className="t-h6">All Services</div>
          </div>
          {studies.map(study => (
            <Fragment key={study.case_study.post_title}>
              <div className="rtable-cell rtable-cell--left">
                {/* <AniLink className={`rtable-cell--link`} to={genCaseStudyUrl(study.case_study.post_title)}><div className="rtable-cell--client">{study.case_study.post_title}</div></AniLink> */}
                <TransitionLink
                  className={`rtable-cell--link`}
                  to={genCaseStudyUrl(study.case_study.post_title)}
                >
                  <div className="rtable-cell--client">
                    {study.case_study.post_title}
                  </div>
                </TransitionLink>
              </div>
              <div className="rtable-cell">
                <div className="rtable-cell--mobile-title t-h6">
                  Featured Services
                </div>
                {study.case_study.acf.case_study_key_properties}
              </div>
              <div className="rtable-cell rtable-cell--foot">
                <div className="rtable-cell--mobile-title t-h6">
                  All Services
                </div>
                {renderCSCats(study.case_study.categories)}

              </div>
            </Fragment>
          ))}
        </div>
      </section>
    </div>
  );
};
export default CaseStudyGrid;

const renderCSCats = categories => {
  var csCatsObj = [];    
  var i = 0;
  for (var cat of categories) {
        
    if (i == categories.length -1) {    
      csCatsObj.push(
        <span key={cat}>
          {unescape(
            (cat = cat.replace(/\&amp;/g, "&"))
          )}
        </span>
      );
    } else {
      csCatsObj.push(
        <span key={cat}>
          {unescape(
            (cat = cat.replace(/\&amp;/g, "&"))
          )}
        , </span>
      );
    }
    
    i++;
  }

  return csCatsObj;
};
